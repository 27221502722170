import {RouteRecordRaw} from "vue-router";

const defaultRoutes: Array<RouteRecordRaw> = [

    {
        path: '/login',
        component: () =>
            import('@/views/login/login.vue'),
        name: 'login',
        meta: {title: '登录', isLogin: false}
    },
    {
        path: '/:pathMatch(.*)',
        component: () =>
            import('@/views/404.vue'),
        name: 'notFound',
        meta: {title: '404'}
    }
]

export default defaultRoutes
