import * as login from './moudle/login'

import * as common from './moudle/common'
import * as user from './moudle/user'
import * as ent from './moudle/ent'
import * as sys from './moudle/sys'
import * as science from './moudle/science'
import * as value from './moudle/value'
import * as indust from './moudle/indust'


export default {
    login,
    common,
    user,
    ent,
    sys,
    science,
    value,
    indust
}
