import {axiosHttp} from "../ajaxData"

// // 科创报告历史查询接口
// export function sciencehistorylist(params:any) {
//     return axiosHttp({
//         method: "post",
//         url: "/module/report/order/order/sciencehistorylist",
//         data:params
//     })
// }

// 获取新能源树状列表查询
export function getEnergyTreeNodes(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/industry/chain/industry/chain/company/info/treeNodes",
        data: params
    })
}


// 获取企业统计
export function getEnterpriseTotalCount(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/industry/chain/industry/chain/company/info/getEnterpriseTotalCount",
        data: params
    })
}


// 获取产业列表
export function getIndustryList() {
    return axiosHttp({
        method: "get",
        url: "/module/industry/chain/industry/chain/company/info/getIndustryList",
    })
}


// 获取产业链企业列表
export function getNewEnergyVehicle(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/industry/chain/industry/chain/company/info/find/page/list",
        data: params
    })
}

// 收藏公司列表
export function getCollectionList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/industry/chain/user/company/collection/find/page/list",
        data: params
    })
}

// 收藏公司
export function postCollectionSave(params:any) {
    return axiosHttp({
        method: "post",
        url: "/module/industry/chain/user/company/collection/save",
        data: params
    })
}

// 取消收藏
export function getCollectionDel(params:any) {
    return axiosHttp({
        method: "delete",
        url: "/module/industry/chain/user/company/collection/del",
        data: params
    })
}
