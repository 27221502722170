import axios from "axios"
import {getStorage} from "@/storage"
// 请求地址

const sever = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 30000,
    headers: {"Content-Type": "application/json"}
})

// 拦截器


sever.interceptors.request.use(
    (config: { headers: any }) => {
        // headers携带token
        const token = getStorage("token")
        Object.assign(config.headers, {
            accessToken: token ? token : null,
            Accept: "application/json"
        })
        return config
    },
    (error: any) => {
        console.log(error)
        Promise.reject(error)
    }
)

export default sever
