import {axiosHttp} from "../ajaxData"


// 删除

export function promotionDel(params:any) {
    return axiosHttp({
        method: "delete",
        url: "/module/promotion/staff/del",
        data:params
    })
}


// 提现列表


export function withdrawalList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/wallet/flow/withdrawal/find/page/list",
        data:params
    })
}

// 分佣列表
export function reportList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/report/order/rebate/find/page/list",
        data:params
    })
}

// 分佣列表


export function channelpageList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/pay/flow/find/channelpage/list",
        data:params
    })
}
//提现账户列表


export function withdrawalListWall(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/user/withdrawal/user/find/withdrawal/user/list",
        data:params
    })
}

// 添加提现账号
export function withdrawalListSave(params:any) {
    return axiosHttp({
        method: "post",
        url: "/module/user/withdrawal/user/save",
        data:params
    })
}

//提现申请

export function withdrawalListpay(params:any) {
    return axiosHttp({
        method: "post",
        url: "/module/wallet/flow/withdrawal/apply",
        data:params
    })
}

//查询钱包


export function walletmoney() {
    return axiosHttp({
        method: "get",
        url: "/module/wallet/user/get/by/user/wallet",
    })
}

// 推广人员链接

export function promotionList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/promotion/promotion/staff/find/page/list",
        data:params
    })
}
export function promotionenable(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/promotion/promotion/staff/enable",
        data:params
    })
}
// 新增推广人员


export function promotionSave(params:any) {
    return axiosHttp({
        method: "post",
        url: "/module/promotion/promotion/staff/save",
        data:params
    })
}

// 生成二维码


export function promotionStaffIdCode(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/promotion/free/promotion/address/qr/code",
        data:params
    })
}

// 企业数字报告下单列表


export function enterprisehistorylist(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/report/order/order/enterprisehistorylist",
        data:params
    })
}

// 结算记录
export function withdrawalListPage(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/wallet/flow/withdrawal/find/page/list",
        data:params
    })
}

// 结算记录详情
export function withdrawalInfo(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/wallet/flow/withdrawal/get/by/id/info",
        data:params
    })
}

// 渠道端分佣列表根据提现单号获得提佣明细表

export function rebateDetailList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/report/order/rebate/get/by/with/drawal/order/no/info",
        data:params
    })
}


// 获取授权信息

export function authInfo(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/auth/auth/get/auth/info",
        data:params
    })
}

// 刷新MD5

export function refreshMd5(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/auth/auth/refresh/md/five",
        data:params
    })
}
// 刷新RSA

export function refreshRsa(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/auth/auth/refresh/rsa",
        data:params
    })
}
// 编辑回调地址

export function callUrl(params:any) {
    return axiosHttp({
        method: "post",
        url: "/module/auth/auth/edit/call/back/url ",
        data:params
    })
}

