import {axiosHttp} from "../ajaxData"

// 价值报告下单
export function saveworth(params:any) {
    return axiosHttp({
        method: "post",
        url: "/module/report/order/order/saveworth",
        data:params
    })
}




export function worthhistorylist(params:any) {
    return axiosHttp({
        method: "post",
        url: "/module/report/order/order/worthhistorylist",
        data:params
    })
}

// 专利详情




export function valueReportDetail(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/valueReport/report/agency/get/by/id/info",
        data:params
    })
}


// 获取企业基础信息



export function companyBaseValue(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/company/base/company/base/info/get/by/order/no",
        data:params
    })
}

// 信贷查询记录


export function companyBaseBank(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/company/base/bank/tax/interactive/get/by/order/no",
        data:params
    })
}

// 股东明细
export function companyBaseShareholder(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/company/base/shareholder/shares/ratio/get/by/order/no",
        data:params
    })
}

// 司法信息

export function judicialInformationJudicial(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/judicialInformation/judicial/details/result/get/by/order/no",
        data:params
    })
}

// 查询纳税信息

export function corporateinformation(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/tax/corporate/tax/information/form/get/by/order/no/info",
        data:params
    })
}

// 查询税务处罚信息

export function taxpenalty(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/tax/tax/penalty/get/info/by/order/no",
        data:params
    })
}
// 查询当前欠税信息
export function las36mtaxarrearcase(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/tax/las36m/taxarrearcase/details/get/info/by/order/no",
        data:params
    })
}

// 查询近三年已交税额信息

export function las3ypaid(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/tax/las3y/per/tax/paid/em/list/get/info/by/order/no",
        data:params
    })
}

// 销售对比分析

export function invoiceParam(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/invoice/invoice/get/order/no/by/param",
        data:params
    })
}


// 近一年前十大客户信息
export function invoiceClient(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/invoice/client/info/find/by/list",
        data:params
    })
}

// 近一年前十大供应商信息
export function invoicesupplier(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/invoice/supplier/info/find/by/list",
        data:params
    })
}

// 查询企业标签
export function companybasic(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/company/base/basic/company/info/tyc/get/by/order/no",
        data:params
    })
}

// 开票金额


export function invoicenearMoney(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/invoice/near/four/years/invoice/find/by/list",
        data:params
    })
}
// 开票数量
export function invoicecusList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/invoice/las/thirty/six/m/cus/val/inv/qty/list/find/by/list",
        data:params
    })
}

//红冲金额
export function invoicecustaxList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/invoice/las/thirty/six/m/cus/red/ink/inv/amt/incl/tax/list/find/by/list",
        data:params
    })
}
// 作废金额
export function invoicecustaxthirty(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/invoice/las/thirty/six/m/cus/void/inv/amt/incl/tax/list/find/by/list",
        data:params
    })
}

// 产品匹配信息法人/股东信息
export function companyBaseshareholder(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/companyBase/product/matching/legel/shareholder/find/by/orderNo",
        data:params
    })
}


// 支付
export function paymentspayments(params:any) {
    return axiosHttp({
        method: "post",
        url: "/module/report/order/order/initiation/of/payments",
        data:params
    })
}
// 获取金额


export function paymentMoneypayments(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/fee/config/find",
        data:params
    })
}

//获取详情



export function orderDetailId(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/report/order/order/channel/order/by/id",
        data:params
    })
}

// pdf 下载
export function download(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/enterprise/report/download/auth",
        data:params
    })
}
// 下载
export function downloadPdf(params:any) {
    return axiosHttp({
        method: "get",
        responseType:"blob",
        url: "/module/enterprise/report/download",
        data:params,
        fileName:'企业数字报告'
    })
}
// 下载
export function downloadPdfV2(params:any) {
    return axiosHttp({
        method: "get",
        responseType:"blob",
        url: "/module/enterprise/report/download/v2",
        data:params,
        fileName:'新企业数字报告'
    })
}
// 渠道端根据id获取订单信息



export function channelOrderId(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/report/order/order/worth/channel/order/by/id",
        data:params
    })
}


// 查询产品匹配列表

export function enterpriseProduct(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/product/enterprise/product/match/result/find/by/list",
        data:params
    })
}

// 司法案件

export function judicialInformation(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/judicialInformation/historical/proceedings/get/by/order/no",
        data:params
    })
}



//行政处罚
export function judicialInformationAdministrative(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/judicialInformation/historical/administrative/punishment/get/by/order/no",
        data:params
    })
}
