//渠道端菜单

const staticRoutes = [
    {
        path: "/",
        name: "layout",
        component: () => import("@/components/layout/index.vue"),
        meta: { title: "首页" },
        children: [
            {
                path: "/scienceReporting/",
                name: "科创报告",
                component: () => import("@/views/reportingManager/scienceReporting/scienceReporting.vue"),

                meta: {
                    icon: "",
                    title: "科创报告",
                    isKeepAlive: true,
                    isMenu: false
                }
            },
            {
                path: "/assessment",
                name: "企业价值报告",
                component: () => import("@/views/valueAssessment/assessment/assessment.vue"),

                meta: {
                    icon: "",
                    title: "企业价值报告",
                    isMenu: false
                }
            },
            {
                path: "/trademarkDetails",
                name: "商标详情",
                component: () => import("@/views/reportingManager/trademarkDetails/trademarkDetails.vue"),

                meta: {
                    icon: "",
                    title: "商标详情",
                    isMenu: false
                }
            },
            {
                path: "/PatentDetail",
                name: "专利详情",
                component: () => import("@/views/reportingManager/PatentDetail/PatentDetail.vue"),

                meta: {
                    icon: "",
                    title: "专利详情",
                    isMenu: false
                }
            }
        ]
    },
    {
        path: "/receipt",
        name: "receipt",
        component: () => import("@/components/layout/index.vue"),
        meta: { title: "税票采集" },
        children: [
            {
                path: "/receipt/gather",
                name: "采集管理",
                component: () => import("@/views/enterprise/receipt/gather/gather.vue"),

                meta: {
                    icon: "",
                    title: "采集管理",
                    isMenu: true
                }
            },
            // 企业数字报告
            {
                path: "/receipt/dataCenter",
                name: "dataCenter",
                component: () => import("@/views/enterprise/dataCenter/dataCenter.vue"),
                meta: {
                    title: "数据中心",
                    isMenu: true
                }
            },
            {
                path: "/receipt/newReportDetails",
                name: "报告详情（new)",
                component: () => import("@/views/enterprise/newReportDetails/newReportDetails.vue"),

                meta: {
                    icon: "",
                    title: "报告详情（new)",
                    isMenu: true
                }
            },
            {
                path: "/receipt/reportDetails",
                name: "报告详情",
                component: () => import("@/views/enterprise/reportDetails/reportDetails.vue"),

                meta: {
                    icon: "",
                    title: "报告详情",
                    isMenu: true
                }
            },
            {
                path: "/receipt/personnel",
                name: "采集人员配置",
                component: () => import("@/views/enterprise/receipt/personnel/personnel.vue"),

                meta: {
                    icon: "",
                    title: "采集人员配置",
                    isMenu: true
                }
            }
        ]
    },
    {
        path: "/account",
        name: "account",
        component: () => import("@/components/layout/index.vue"),
        meta: { title: "账户管理" },
        children: [
            {
                path: "/account/wallet",
                name: "我的钱包",
                component: () => import("@/views/enterprise/account/wallet/wallet.vue"),

                meta: {
                    icon: "",
                    title: "我的钱包",
                    isMenu: true
                }
            },
            {
                path: "/account/wallentList",
                name: "提现记录",
                component: () => import("@/views/enterprise/account/wallentList/wallentList.vue"),

                meta: {
                    icon: "",
                    title: "提现记录",
                    isMenu: true
                }
            },
            {
                path: "/account/withdrawal",
                name: "提现",
                component: () => import("@/views/enterprise/account/withdrawal/withdrawal.vue"),

                meta: {
                    icon: "",
                    title: "提现记录",
                    isMenu: true
                }
            },
            {
                path: "/account/walletStatus",
                name: "提现结果",
                component: () => import("@/views/enterprise/account/walletStatus/walletStatus.vue"),

                meta: {
                    icon: "",
                    title: "提现结果",
                    isMenu: true
                }
            },
            {
                path: "/account/addWallet",
                name: "添加账户",
                component: () => import("@/views/enterprise/account/addWallet/addWallet.vue"),

                meta: {
                    icon: "",
                    title: "添加账户",
                    isMenu: true
                }
            },
            {
                path: "/account/paymentStatement",
                name: "支付明细",
                component: () => import("@/views/enterprise/account/paymentStatement/paymentStatement.vue"),

                meta: {
                    icon: "",
                    title: "消费明细",
                    isMenu: true
                }
            },
            // {
            //     path: '/account/settlement',
            //     name: '结算记录',
            //     component: () => import("@/views/enterprise/account/settlementRecords/settlementRecords.vue"),
            //
            //     meta: {
            //         icon: '',
            //         title: '结算记录',
            //     },
            // },
            {
                path: "/account/detail",
                name: "充值明细",
                component: () => import("@/views/system/detail/detail.vue"),

                meta: {
                    icon: "",
                    title: "充值明细",
                    isMenu: true
                }
            },
            {
                path: "/account/consumption",
                name: "消费明细",
                component: () => import("@/views/system/consumption/consumption.vue"),

                meta: {
                    icon: "",
                    title: "消费明细",
                    isMenu: true
                }
            }

        ]
    },
    {
        path: "/industrial",
        name: "industrial",
        component: () => import("@/components/layout/index.vue"),
        meta: { title: "产业链诊断" },
        children: [
            {
                path: "/industrial/",
                name: "产业链诊断",
                component: () => import("@/views/industrial/industrial.vue"),
                meta: {
                    icon: "",
                    title: "产业链诊断"
                }
            }
        ]
    }
];


export default staticRoutes;
