import { createApp } from "vue";
const app = createApp(App)
import "@/assets/css/index.scss";
import App from "./App.vue";
import router from "./router";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css'
import pinia from '@/store/index'
import Api from './api/index'
import * as Icons from "@ant-design/icons-vue";

// 注册icon组件
Object.keys(Icons).forEach(key => {
    app.component(key, Icons[key as keyof typeof Icons]);
});

import mitt from 'mitt'
const Mit = mitt()
declare module 'vue' {
    export interface ComponentCustomProperties {
        $Bus: typeof Mit
    }
}
app.config.globalProperties.$Bus = Mit
import directives from '@/directives';
import Directives from "@/directives/directives"
app.use(Directives)
app.config.globalProperties.$api = Api
app.use(Antd);
app.use(router)
app.use(pinia)
app.use(directives)
app.mount("#app")

