import {axiosHttp} from "../ajaxData"

// 获取省市区

export function getDistrictAll() {
    return axiosHttp({
        method: "get",
        url: "/system/district/districtAll",
    })
}

// 上传文件

export function uploadFileAvatar() {
    return process.env.VUE_APP_BASE_API + "/system/user/profile/avatar"
}
export function uploadFile() {
    return process.env.VUE_APP_BASE_API + "/resource/oss/upload"
}

// 批量导入监控

export function importTemplate() {
    return process.env.VUE_APP_BASE_API + "/patent/patentMonit/importData"
}
//
// 获取短信验证码

export function sendSmsCode(params: any) {

    return axiosHttp({
        method: "get",
        url: "/resource/sms/code",
        data: params,
    })
}


// 发送邮箱


export function sendEmaliCode(params: any) {

    return axiosHttp({
        method: "get",
        url: "/resource/email/code",
        data: params,
    })
}


export function dataType(params: any) {

    return axiosHttp({
        method: "get",
        url: `communal/type/find/by/code`,
        data:params
    })
}


export function download(ossId:any) {
    return axiosHttp({
        method: "get",
        responseType:'blob',
        url: `/resource/oss/download/${ossId}`,
    })
}

// 下载信息确认单


export function informationDown(params:any) {
    return axiosHttp({
        method: "get",
        responseType:'blob',
        url: `/patent/patent/informationDown`,
        data:params
    })
}
// 下载委托书


export function matchbookDown(params:any) {
    return axiosHttp({
        method: "get",
        responseType:'blob',
        url: `/patent/patent/matchbookDown`,
        data:params
    })
}

// 下载合同

export function matchbookHtong(params:any) {
    return axiosHttp({
        method: "get",
        responseType:'blob',
        url: `/patent/patent/matchbookDown`,
        data:params
    })
}

export function getBaseUrl() {
    return axiosHttp({
        method: "get",
        url: "/module/other/annex/get/by/url"
    })
}