import {defineStore} from "pinia";
import piniaPersistConfig from "@/config/piniaPersist";
import {UserState} from "../interface";
import {clearStorage} from "@/storage";
import {login, userInfo} from '@/api/moudle/login'
import {walletmoney} from '@/api/moudle/ent'
import router from "@/router";
import {getBaseUrl} from '@/api/moudle/common'

export const useUserStore = defineStore({
    id: "user",
    state: (): UserState => ({
        userInfo: {},
        wallet: {
            usableRebateMoney: 0
        },
        baseImgUrl: ''
    }),
    getters: {},
    actions: {
        /**
         * @desc: 设置用户信息
         * @param { UserState } userInfo
         */
        setUserInfo(userInfo: UserState["userInfo"]) {
            this.userInfo = userInfo;
        },

        /** 登出 */
        async logout() {
            this.resetToken();
            clearStorage()
        },
        getUserInfo() {
            userInfo().then((res: any) => {
                this.userInfo = res.data
            });


        },
        getWallent() {
            walletmoney().then((res: any) => {
                this.wallet = res.data
            })
        },
        /** 清空token及用户信息 */
        resetToken() {
            this.userInfo = {};
            clearStorage()
        },
        async get_base_url() {
            // @ts-ignore
            const {data} = await getBaseUrl();

            if (data) {
                // @ts-ignore
                this.baseImgUrl = data

            }
        },

    },
    persist: piniaPersistConfig("user")
});
