// ./src/directives/index.ts

import type { App } from 'vue';
import watermark from './waterMark';

export default function installDirective(app: App) {
    // 水印指令
    app.directive(watermark.name, watermark.directives);
}

