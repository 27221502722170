<template>
  <div>
    <a-config-provider :locale="zhCN">
      <router-view />
    </a-config-provider>
    <a-back-top />
  </div>
</template>

<script setup>
import zhCN from "ant-design-vue/es/locale/zh_CN"
import dayjs from "dayjs"
import "dayjs/locale/zh-cn"

dayjs.locale("zh-cn")
window.addEventListener("resize", function () {
    // 改变视口大小重新加载页面
    window.location.href = window.location.href
});(() => {
    function block() {
        if (
            window.outerHeight - window.innerHeight > 200 || window.outerWidth - window.innerWidth > 200
        ) {
            document.body.innerHTML = "检测到非法调试,请关闭后刷新重试!"
        }
        setInterval(() => {
            (function () {
                return false
            })
                ["constructor"]("debugger")
                ["call"]()
        }, 50)
    }
    try {
        block()
    } catch (err) {}
})()
</script>

<style lang="scss">
@import "./assets/css/main.css";
@import "./assets/css/normalize.css";
@import "./assets/iconfont/iconfont.css";
</style>
