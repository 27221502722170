import {axiosHttp} from "../ajaxData"

// 科创搜索检索
export function searchKeyword(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/company/base/company/base/info/get/by/keyword",
        data:params
    })
}

// 科创报告下单
export function saveScience(params:any) {
    return axiosHttp({
        method: "post",
        url: "/module/report/order/order/savescience",
        data:params
    })
}
// 科创报告历史查询接口

export function sciencehistorylist(params:any) {
    return axiosHttp({
        method: "post",
        url: "/module/report/order/order/sciencehistorylist",
        data:params
    })
}

// 获取企业信息

export function getReportEnterpriseDetail(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/enterprise/right/knowledge/get/report/enterprise/detail",
        data:params
    })
}

//知识产权盘点



export function technologyInfo(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/technology/technology/scale/get/by/order/id/info",
        data:params
    })
}

// 企业研发/技术领域分析

export function technologyLayoutInfo(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/technology/technology/layout/get/by/order/id/info",
        data:params
    })
}

// 根据订单编号获取科创报告技术质量信息

export function technologyqualityInfo(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/technology/technology/quality/get/info/by/order/id",
        data:params
    })
}
// 根据订单编号获取科创报告技术影响力信息




export function technologinfluenceityInfo(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/technology/technology/influence/get/by/order/id/info",
        data:params
    })
}

// 获取知产信息专利



export function knowledgeList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/report/knowledge/patent/gen/knowledge/patent/page",
        data:params
    })
}

// 专利详情



export function knowledgePatentDetail(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/report/knowledge/patent/gen/knowledge/patent/by/id",
        data:params
    })
}


// 获取知产信息商标、

export function trademarkList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/enterprise/right/knowledge/get/knowledge/trademark",
        data:params
    })
}
// 获取商标详情

export function trademarkDetail(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/enterprise/right/knowledge/get/knowledge/trademark/detail",
        data:params
    })
}
// 获取知产信息软著、

export function copyrightList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/enterprise/right/knowledge/get/knowledge/software/copyright",
        data:params
    })
}




// 获取科创能力分析
export function capabilityRatingAnalysis(params:any) {
    return axiosHttp({
        method: "get",
        url: "module/ads/assignees/enterprise/mark/ads/get/by/name/info",
        data:params
    })
}

// 获取企业能力评级
export function capabilityRatingLevel(params:any) {
    return axiosHttp({
        method: "get",
        url: "module/ads/assignees/enterprise/mark/ads/get/by/name/find/level",
        data:params
    })
}

// 获取企业评级统计
export function capabilityRatingStatistics() {
    return axiosHttp({
        method: "get",
        url: "/module/ads/assignees/enterprise/mark/ads/statistics/all/companies/level",
    })
}


