import {createRouter, createWebHashHistory, RouteRecordRaw, createWebHistory} from "vue-router";
// @ts-ignore
import NProgress from "./nprogress"
import defaultRoutes from './defaultRoutes'

import staticRoutes from './staticRoutes/staticRoutes'
import {getStorage, clearStorage} from '@/storage'
import {useMenuStore} from "@/store/modules/menu";
import {useUserStore} from "@/store/modules/user";


NProgress.configure({
    easing: "ease", // 动画方式
    speed: 300, // 递增进度条的速度
    showSpinner: true, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.3 // 初始化时的最小百分比
})
const routes: Array<RouteRecordRaw> = [...defaultRoutes,...staticRoutes]


const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    NProgress.start()
    const token: any = getStorage("token")
    if (to.path === '/login') {
        next();
    }else {
        if (token) {
            next();
            const userStore = useUserStore();
            userStore.getWallent()
        } else {
            next('/login');
        }
    }

})
router.afterEach(() => {
    // 在即将进入新的页面组件前，关闭掉进度条
    NProgress.done()
})

export default router;
