import {axiosHttp} from "../ajaxData"

// 帐号密码登录


// 登录
export function login(params:any) {
    return axiosHttp({
        method: "post",
        url: "/login",
        data: params,
        isFromData:true
    })
}

// 获取个人信息
export function userInfo() {
    return axiosHttp({
        method: "get",
        url: "/module/user/user/info/get/by/id/info"
    })
}
