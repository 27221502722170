
import {axiosHttp} from "../ajaxData"

// 帐号密码登录


// 登录
export function userInfo() {
    return axiosHttp({
        method: "get",
        url: "/module/user/user/get/by/id/info",
    })
}
// 修改昵称
export function editNickName(params:any) {
    return axiosHttp({
        method: "put",
        url: "/module/user/user/info/edit/nickname",
        data:params
    })
}

// 短信验证码


export function smsCode() {
    return axiosHttp({
        method: "get",
        url: "/sms/modify/password",
    })
}
// 手机号码修改密码



export function passwordEdit(params:any) {
    return axiosHttp({
        method: "post",
        url: "/module/user/user/modify/password",
        data:params
    })
}
