import {axiosHttp} from "../ajaxData"

// 分页查询企业钱包充值流水
export function walletRechargeList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/wallet/flow/recharge/find/page/list",
        data:params
    })
}
// 分页查询企业钱包消费流水
export function reportorderwalletRechargeList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/reportorder/enterprise/wallet/consume/flow/find/page/list",
        data:params
    })
}


// 根据编号查询科创钱包消费流水
export function reportorderRechargeList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/reportorder/science/wallet/consume/flow/find/page/list",
        data:params
    })
}


// 根据编号查询价值评估钱包消费流水
export function reportorderworthRechargeList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/reportorder/worth/wallet/consume/flow/find/page/list",
        data:params
    })
}

//钱包扣费流水


export function walletconsume(params:any) {
    return axiosHttp({
        method: "get",
        url: "/module/flow/wallet/consume/flow/find/page/list",
        data:params
    })
}