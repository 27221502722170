import request from "./request"
import {message} from 'ant-design-vue';
import router from "@/router";
import {clearStorage, getStorage} from "../storage"
export function axiosHttp(apiRoute: {
    method: any
    url: any
    data?: any
    responseType?: any
    isFromData?: any,
    isDownload?: boolean,
    fileName?:string
}) {
    return new Promise((resolve, reject) => {
        request({
            method: apiRoute.method,
            url: apiRoute.url,
            headers: {
                "Content-Type": apiRoute.isFromData ? 'multipart/form-data' : 'application/json',
                "Wtype": getStorage('Wtype')
            },
            params: apiRoute.method === "get" || apiRoute.method === "delete" || apiRoute.method === "put" ? apiRoute.data : undefined,
            data: apiRoute.method == "post" ? apiRoute.data : undefined,
            responseType: apiRoute.responseType
        })
            .then((response: { status: number; data: any }) => {
                if (response.status === 200) {
                    if (response.data.code === '0') {
                        resolve(response.data)
                    } else if (apiRoute.responseType === "blob" ) {
                        blobDownloadFile(
                            response.data,
                            "application/pdf;charset=UTF-8",
                            response,
                            apiRoute.fileName
                        )
                        resolve(response.data) // 添加回调用于在下载完毕后关闭loading
                    } else if (response.data.code == 401) {
                        message.error('登录过期，请重新登录！')
                        clearStorage()
                        sessionStorage.clear()
                        localStorage.clear()
                        setTimeout(() => {
                            router.push('/login')
                        }, 2000)
                    } else if (response.data.code == 9500) {
                        resolve(response.data)
                    } else if (response.data.code == '-1') {
                        message.error(response.data.msg ?
                            response.data.msg :
                            "网络请求出错，请稍后再试"
                        )
                        reject(response.data)
                    }else {
                        message.error(response.data.msg ?
                            response.data.msg :
                            "网络请求出错，请稍后再试"
                        )
                        reject(response.data)
                    }
                } else {
                    message.error(response.data.msg ?
                        response.data.msg :
                        "网络请求出错，请稍后再试")
                    reject(response.data)
                }
            })
            .catch((error: { response: { data: { msg: any } } | undefined }) => {
                message.error(
                    error.response !== undefined ?
                        error.response.data.msg ?
                            error.response.data.msg :
                            "请求未找到" :
                        "网络请求出错，请稍后再试",
                )
                reject(error.response)
            })
    })
}


// res 是返回的文件流，type 是文件MIME类型, fileName 是给下载的文件一个名称
const blobDownloadFile = (res: any, type: string, headers: any,fileName:any) => {
    const blob = new Blob([res], {
        type: type
    })

    const a = document.createElement("a")
    const URL = window.URL || window.webkitURL
    const herf = URL.createObjectURL(blob)
    a.href = herf
    // a.download = decodeURI(headers.headers['download-filename'])
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(herf)
}
